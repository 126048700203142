// Le Header

header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 10;

  @include media-breakpoint-down(md) {
    background: var(--background-color-image);
    //box-shadow: 0 55px 55px var(--background-color-image);
  }
}

.logo-container {
  flex: 0 1 50%;


  .logo {
    padding-left: var(--column-padding);

    a {
      border: none;
    }

    @include media-breakpoint-up(md) {
      box-shadow: 0 55px 55px var(--background-color-text);
      background: var(--background-color-text);
      padding-right: 20px;
    }
  }

  svg {
    width: 140px;
    height: 70px;

    @media (min-width:768px) {
      width: 230px;
      height: 150px;
    }
  }
}

.theme-switch-wrapper {
  flex: 0 1 50%;
  padding-right: var(--column-padding);
  justify-content: flex-end;
}
