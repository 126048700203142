// toggle able imprint
.imprint {
  margin-top: 2em;

  details {
    summary {
      cursor: pointer;
      list-style: none;
    }
  }
}

summary::marker {
  display: none;
}

// dont display the marker on webkit
details summary::-webkit-details-marker {
  display: none;
}

 

.imprint-toggler {
  background: transparent;
  border: none;
  color: var(--text);
  display: flex;
  align-items: center;

  span {
    padding-left: .4em;
  }

  svg {
    height: .9em;
    width: .9em;
    transition: transform .2s ease-out;
  }
}

.open .imprint-toggler svg {
  transform: rotate(90deg);
}