* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: var(--text);
  font-family: var(--body-font);
  font-size: var(--base-font-size);
}

h1,
h2 {
  color: var(--text);

}

@include media-breakpoint-up(sm) {

  h1,
  h2 {
    color: var(--text);
    font-size: 2.3em;
    font-weight: 500;
  }
}

p {
  margin-bottom: 2em;
}

address {
  font-style: normal;
}

a {
  color: var(--text);
  text-decoration: none;
  //text-decoration: underline;
  //text-decoration-thickness: 2px;
  border-bottom: 2px solid;
  transition: all .1s;

  &:hover {
    //text-decoration-thickness: 5px;
    border-bottom: 4px solid;
  }
}

a.btn {
  position: relative;
  top: 0;
  color: var(--text);
  text-decoration: none;
  border: 2px solid var(--text);
  padding: 0.6em 1.2em;
  display: inline-block;
  transition: all .1s;
  font: 500 .9em/1.4 var(--body-font);

  &:hover {
    border-bottom: 5px solid var(--text);
    top: -3px;

  }
}

ul {
  margin: 0 0 2em 0;
  padding: 0;

  li {
    margin-left: 1em;

    ul {
      padding: .5em 0;
      margin: 0;

      li {
        margin-left: 1em;
      }
    }
  }

}
