// definieng the Conatiner


.container,
.grid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px
  }

  @media (min-width: 768px) {
    max-width: 720px
  }

  @media (min-width: 992px) {
    max-width: 960px
  }

  @media (min-width: 1200px) {
    max-width: 1140px
  }

  @media (min-width: 1600px) {
    max-width: 1500px
  }
}

// basic structures

@media (min-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 30px;
  }
}

.text-element-7-4 {
  :nth-child(1) {
    grid-column: 1 / 8;
  }

  :nth-child(2) {
    grid-column: 9 / 13;
  }
}

.text-element-5-3-3 {
  :nth-child(1) {
    grid-column: 1 / 6;
  }

  :nth-child(2) {
    grid-column: 7 / 10;
  }

  :nth-child(3) {
    grid-column: 10 / 13;
  }
}

.text-element-6-6 {
  :nth-child(1) {
    grid-column: 1 / 6;
  }

  :nth-child(2) {
    grid-column: 7 / 13;
  }
}

.text-element-3-3-3 {
  :nth-child(1) {
    grid-column: 1 / 5;
  }

  :nth-child(2) {
    grid-column: 5 / 9;
  }

  :nth-child(3) {
    grid-column: 9 / 13;
  }
}
