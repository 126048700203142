// social icons

ul#social-nav,
ul.social-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 3em 0 0 -15px;
  padding: 0;

  li {
    padding: 0;
    margin: 0 15px;

    a {
      display: inline-block;
      text-decoration: none;
      position: relative;
      text-align: center;
      transition: opacity .2s;
      color: var(--text);
      padding-right: 1.8em;
      border: none;

      &:after {
        display: none;
      }

      svg {
        width: 2em;
        height: 2em;
      }

      &:hover {
        opacity: .7;
      }
    }

  }
}
