.lang-switcher {
  margin-top: 2em;
  margin-bottom: .5em;

  a {
    color: var(--text);
    text-decoration: none;
    border-bottom: 0px solid;
    transition: all 0.1s;

    &:hover {
      border-bottom: 2px solid;
    }
  }

  span {
    color: var(--text);
    text-decoration: none;
    border-bottom: 2px solid;
  }
}
