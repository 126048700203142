/* fira-mono-regular - latin */
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-mono-v9-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/fira-mono-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/fira-mono-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/fira-mono-v9-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/fira-mono-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/fira-mono-v9-latin-regular.svg#FiraMono') format('svg');
  /* Legacy iOS */
}

/* fira-mono-500 - latin */
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/fira-mono-v9-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/fira-mono-v9-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/fira-mono-v9-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/fira-mono-v9-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/fira-mono-v9-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/fira-mono-v9-latin-500.svg#FiraMono') format('svg');
  /* Legacy iOS */
}
